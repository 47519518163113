export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
};

export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  if (
    prevRouterProps?.location.pathname === "/en-us/blog" &&
    routerProps.location.pathname === "/en-us/blog"
  ) {
    return false;
  }

  return true;
};

export const onRouteUpdate = ({ location }) => {
  if (
    process.env.NODE_ENV !== `production` ||
    typeof window.ga === "undefined"
  ) {
    return null;
  }

  if (!window.dynamicRouteChange) {
    window.ga("create", `${process.env.GATSBY_GOOGLE_ANALYTICS_ID}`, "auto");
    window.dynamicRouteChange = true;
    return null;
  }

  setTimeout(() => {
    window?.vgo?.("update");
  }, 32);

  // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined;

    window.ga("set", "page", pagePath);
    window.ga("send", "pageview");
  };

  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(sendPageView);
    });
  } else {
    // simulate 2 rAF calls
    setTimeout(sendPageView, 32);
  }
  return null;
};
